<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <PageHeader title="Tools" />
    <main
      class="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
    >
      <!-- Stripe Transactions -->
      <router-link
        to="/tools/transactions"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="h-8 w-8 mr-5 stroke-current"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.25"
          viewBox="0 0 24 24"
        >
          <path
            d="M19.066 21.227a8.51 8.51 0 01-7.087 0 8.508 8.508 0 00-7.087.708 4.258 4.258 0 002.835-3.543V7.762c.019-3.097 2.572-5.635 5.67-5.635a5.675 5.675 0 014.89 2.8m-3.615 8.505H4.75"
          />
        </svg>
        <h2 class="text-lg uppercase">Stripe Transactions</h2>
      </router-link>

      <!-- Return Codes -->
      <router-link
        to="/tools/returncodes"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="h-8 w-8 mr-5 stroke-current"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
        >
          <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
          <path d="M7 11V7a5 5 0 0110 0v4" />
        </svg>
        <h2 class="text-lg uppercase">Return Codes</h2>
      </router-link>

      <!-- DVSA work orders -->
      <router-link
        to="/tools/workorders"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="stroke-current h-10 w-10 mr-5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          viewBox="0 0 24 24"
        >
          <path
            d="M16 6h3a1 1 0 011 1v11a2 2 0 01-4 0V5a1 1 0 00-1-1H5a1 1 0 00-1 1v12a3 3 0 003 3h11M8 8h4M8 12h4M8 16h4"
          />
        </svg>
        <h2 class="text-lg uppercase">DVSA Work Orders</h2>
      </router-link>

      <!-- Ivy code gen -->
      <router-link
        to="/tools/ivycodegen"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="stroke-current h-10 w-10 mr-5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          viewBox="0 0 24 24"
        >
          <circle cx="8" cy="15" r="4" />
          <path d="M10.85 12.15L19 4M18 5l2 2M15 8l2 2" />
        </svg>
        <h2 class="text-lg uppercase">Return Code Gen</h2>
      </router-link>

      <!-- Ivy clients -->
      <router-link
        to="/tools/ivyclients"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="stroke-current h-10 w-10 mr-5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          viewBox="0 0 24 24"
        >
          <path
            d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 11-7.778 7.778 5.5 5.5 0 017.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"
          />
        </svg>
        <h2 class="text-lg uppercase">iV Clients</h2>
      </router-link>

      <!-- Ivy Events -->
      <router-link
        v-if="$getExperimentStatus('ivy-events-tool')"
        to="/tools/ivyevents"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="stroke-current h-8 w-8 mr-5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          viewBox="0 0 24 24"
        >
          <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
          <path d="M16 2v4M8 2v4M3 10h18" />
        </svg>
        <h2 class="text-lg uppercase">Events</h2>
      </router-link>

      <!-- Engineer Access -->
      <!-- v-if="$getExperimentStatus('ivy-events-tool')" -->
      <router-link
        to="/tools/ivylogins"
        class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
          class="stroke-current h-8 w-8 mr-5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          viewBox="0 0 24 24"
        >
          <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
          <circle cx="9" cy="7" r="4" />
          <path d="M23 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75" />
        </svg>
        <h2 class="text-lg uppercase">iV Logins</h2>
      </router-link>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");

export default {
  name: "Tools",
  components: {
    PageHeader,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
